import { readState } from "@/__main__/app-state.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import getSHA256Hash from "@/util/get-hash.mjs";

export default async function getEmail() {
  const e = readState.user?.email;
  if (e) {
    const hash = await getSHA256Hash(e);
    return hash;
  }

  const hash = await blitzMessage(EVENTS.PLATFORM_ID);
  return hash;
}
